<template>
    <div class="section">
        <div class="container">
            <div class="columns is-centered">
                <div class="column is-6">
                    <h1>Login</h1>
                    <div class="my-4 box">
                        <o-field label="Email">
                            <o-input v-model="username"></o-input>
                        </o-field>
                        <o-field label="Password">
                            <o-input type="password" @keydown="catchEnter($event)" v-model="password"></o-input>
                        </o-field>
                        <div>
                            <o-button :disabled="loggingIn" @click="Login">Login</o-button>
                            <o-button variant="info" inverted tag="router-link" :disabled="undefined" :to="{ name: 'register' }">Create an account</o-button>
                            <o-button variant="info" inverted tag="router-link" class="float-right" :disabled="undefined" :to="{ name: 'Forgot' }">Forgot password?</o-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const loggingIn = ref(false);
            const username = ref("");
            const password = ref("");

            const Login = async () => {
                loggingIn.value = true;
                try {
                    var response = await $http.post("/api/user/login", { username: username.value, password: password.value });
                    global.$store.dispatch("login", response.data);
                    $success("Successfully logged in.");
                    global.$router.push({ name: "dashboard" });
                }
                catch (err) {
                    $error("Failed to login.");
                }
                loggingIn.value = false;
            };

            const catchEnter = function (e) {
                if (e.keyCode === 13 && !this.loading) {
                    Login();
                }
            };

            return {
                loggingIn,
                username,
                password,
                Login,
                catchEnter
            }
        }
    }
</script>
<style lang="scss" scoped>
    .float-right {
        float: right;
    }
</style>